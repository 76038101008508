import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

// Components
import Button from '../../components/Button/Button';
import FormSelect from '../../components/Form/FormSelect';
import FormText from '../../components/Form/FormText';
import Loader from '../../components/Loader/Loader';

// api
import { useGetClients } from '../../api/hooks/clients.hooks';
import { useCreateAvoir } from '../../api/hooks/avoirs.hooks';

export default function NewAvoirForm() {
    const history = useHistory();

    const { data: clients } = useGetClients();
    const [options, setOptions] = useState([]);
    useEffect(() => {
        if (clients) {
            const list = [];
            clients.map(({ id, nom }) =>
                list.push({
                    value: id,
                    label: nom,
                })
            );
            setOptions(list);
        }
    }, [clients]);

    const { isLoading: loadingAvoir, mutate: createAvoir } = useCreateAvoir();

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    async function onSubmit(formData) {
        const { avoir_restocker, ...rest } = formData
        createAvoir({ avoir_restocker: Boolean(parseInt(avoir_restocker, 10)), ...rest }, {
            onSuccess: (response) => history.push(`avoirs/${response.data.id}`),
        });
    }

    return (
        <div className='p-4'>
            <form onSubmit={handleSubmit(onSubmit)} className='form' noValidate>
                <FormSelect
                    label='Client'
                    placeholder='-- Choisissez un client --'
                    name='entite_id'
                    register={register}
                    errors={errors}
                    options={options}
                />
                <FormText
                    label="Nom de l'avoir"
                    name='avoir_nom'
                    placeholder="Nom de l'avoir"
                    register={register}
                    errors={errors}
                />
                <FormSelect
                    label='Restocker les produits à la validation'
                    placeholder='-- Choisissez une option --'
                    name='avoir_restocker'
                    register={register}
                    errors={errors}
                    options={[
                        { value: 0, label: "Non" },
                        { value: 1, label: "Oui" }
                    ]}
                />

                <div className='flex right'>
                    <Button disabled={loadingAvoir}>
                        Créer l'avoir
                        {loadingAvoir && <Loader />}
                    </Button>
                </div>
            </form>
        </div>
    );
}

NewAvoirForm.propTypes = {};
