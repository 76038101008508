/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Cell } from 'rsuite-table';
import Badge from '../Badge/Badge';

const NameCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props} className="name-cell">
        <p>{rowData.nom}</p>
        <div className="name-cell__ref">
            <p>Référence : {rowData.reference}</p>
            {rowData.reference_externe && <p>Référence externe : {rowData.reference_externe}</p>}
        </div>
        <div className="flex">
            {rowData.categories?.map((category) => (
                <Badge key={category} content={category} />
            ))}
        </div>
    </Cell>
);

export default NameCell;
